<template>
  <div v-if="!showDontHaveService" id="cupones">
    <v-container>
      <v-row align="center" justify="center">
        <v-card elevation="0" max-width="1100" width="100%" class="transparent mb-5">
          <v-toolbar flat class="transparent">
            <h1 class="headline">{{ $t('menu.cupones') }}</h1>
            <v-spacer/>
            <v-btn
              data-test="settings-cupon-button-create"
              depressed
              rounded
              color="primary"
              dark
              class="mb-2"
              @click="
                dialog = true;
                autoGenerarCodigo();
              "
            >{{ $t('cupones.crear') }}
            </v-btn
            >
            <v-dialog v-model="dialog" max-width="500px" scrollable>
              <v-card>
                <v-card-title>
                  <span class="headline">{{ formTitle }}</span>
                </v-card-title>
                <v-card-text>
                  <v-container grid-list-md>
                    <v-layout wrap>
                      <v-flex xs12 sm6 md6>
                        <!--<i class="fa fa-magic" @click="autoGenerarCodigo"></i>-->
                        <v-text-field
                          data-test="settings-cupon-input-code"
                          v-model="editedItem.codigo"
                          class="text_codigo"
                          :rules="rulesCode"
                          name="input-10-1"
                          :label="$t('cupones.codigo')"
                        />
                      </v-flex>
                      <v-flex xs12 sm6 md6>
                        <v-text-field
                          data-test="settings-cupon-input-discount"
                          v-model="editedItem.descuento"
                          :label="$t('cupones.descuento')"
                          type="number"
                          min="0"
                          max="100"
                          suffix="%"
                          @change="cambiaDescuento"
                        />
                      </v-flex>
                      <v-flex xs12 sm6 md6>
                        <v-select
                          data-test="settings-cupon-select-expiration"
                          v-model="editedItem.caducidad"
                          :items="tiposcaducidad"
                          :label="$t('cupones.caducidad')"
                          auto
                          required
                          item-text="name"
                          item-value="id"
                        />
                      </v-flex>
                      <v-flex xs12 sm6 md6>
                        <v-text-field
                          data-test="settings-cupon-input-usages"
                          v-if="editedItem.caducidad === 1"
                          v-model="editedItem.n_usos"
                          name="input-10-1"
                          :label="$t('cupones.num_usos')"
                          @change="cambiaNumUsos"
                        />
                        <v-menu
                          v-if="editedItem.caducidad === 2"
                          ref="fecha"
                          v-model="editedItem.fechaMenu"
                          class="mr-2"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          full-width
                          max-width="290px"
                          :return-value.sync="editedItem.fecha"
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              data-test="settings-cupon-input-expiration-date"
                              v-model="editedItem.fecha"
                              :label="$t('common.date')"
                              append-icon="mdi-calendar"
                              readonly
                              class="input_fnacimiento"
                              v-on="on"
                            />
                          </template>
                          <v-date-picker
                            v-model="editedItem.fecha"
                            first-day-of-week="1"
                            :locale="this.$i18n.locale"
                            :min="new Date().toISOString().substr(0, 10)"
                            no-title
                            scrollable
                          >
                            <v-spacer/>
                            <v-btn text color="grey darken-4" @click="editedItem.fechaMenu = false">{{
                                $t('common.cancel')
                              }}
                            </v-btn>
                            <v-btn depressed color="primary" @click="$refs.fecha.save(editedItem.fecha)">OK</v-btn>
                          </v-date-picker>
                        </v-menu>
                      </v-flex>
                      <v-flex xs12 sm12 md12>
                        <v-autocomplete
                          data-test="settings-cupon-input-patient"
                          v-model="editedItem.paciente"
                          :items="pacientes"
                          chips
                          item-text="name"
                          item-value="id"
                          style="width: 98%"
                          :label="$t('common.paciente')"
                        >
                          <template slot="selection" slot-scope="data">
                            <v-chip
                              color="primary lighten-4"
                              :input-value="data.selected"
                              close
                              class="chip--select-multi"
                              @click:close="quitarDestinatario()"
                            >
                              {{ data.item.name }}
                            </v-chip>
                          </template>
                          <template slot="item" slot-scope="data">
                            <template v-if="typeof data.item !== 'object'">
                              <v-list-item-content v-text="data.item"/>
                            </template>
                            <template v-else>
                              <v-list-item-content>
                                <v-list-item-title>{{ data.item.name }}</v-list-item-title>
                              </v-list-item-content>
                            </template>
                          </template>
                        </v-autocomplete>
                      </v-flex>
                    </v-layout>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer/>
                  <v-btn data-test="settings-cupon-button-cancel" color="grey darken-3" text @click="close">{{ $t('common.cancel') }}</v-btn>
                  <v-btn data-test="settings-cupon-button-save" color="primary" depressed @click="save">{{ $t('common.create') }}</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>

          <v-card>
            <v-data-table :headers="headers" :items="cupones">
              <template v-slot:item="props">
                <tr>
                  <td width="20%">{{ props.item.codigo }}</td>
                  <td width="50%">{{ props.item.descripcion }}</td>
                  <td width="15%">{{ props.item.descuento_pto }}</td>
                  <td width="10%" class="text-right">
                    <v-btn v-tooltip="$t('common.edit')" icon color="grey" @click="editItem(props.item)">
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                    <v-btn v-tooltip="$t('common.delete')" icon color="grey" @click="deleteItem(props.item)">
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </template>

              <template slot="no-data">
                {{ $t('cupones.sin_cupones') }}
              </template>
            </v-data-table>
          </v-card>

          <!-- Modal Eliminar cupon -->
          <v-dialog v-model="dialog_delete.dialog" dialog_delete max-width="500px">
            <v-card>
              <v-card-title>
                <span class="headline">{{ $t('cupones.eliminar_titulo') }}</span>
              </v-card-title>
              <v-divider/>
              <v-card-text>
                <p>{{ $t('cupones.eliminar_texto') }}</p>
              </v-card-text>
              <v-divider/>
              <v-card-actions>
                <v-spacer/>
                <v-btn color="grey darken-1" text @click.native="dialog_delete.dialog = false"
                >
                  <v-icon>mdi-chevron-left</v-icon>
                  {{ $t('common.cancel') }}
                </v-btn
                >
                <v-btn
                  color="red darken-1"
                  text
                  @click.native="
                    dialog_delete.dialog = false;
                    borrarCupon();
                  "
                >
                  <v-icon>mdi-delete</v-icon>
                  {{ $t('common.confirm') }}
                </v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>

          <!-- Snackbar para mostrar avisos -->
          <v-snackbar
            v-model="snackbar"
            :timeout="timeout"
            :color="alerta.color"
            top="top"
            :multi-line="mode === 'multi-line'"
            :vertical="mode === 'vertical'"
          >
            <v-icon v-if="alerta.icono !== ''">{{ alerta.icono }}
            </v-icon
            >&nbsp;{{ alerta.texto }}
          </v-snackbar>
        </v-card>
      </v-row>
    </v-container>
  </div>
  <div v-else>
    <no-service-granted view="cupones"/>
  </div>
</template>

<script>
import {haveEprescriptionActive} from '@/utils';
import NoServiceGranted from '@/components/common/NoServiceGranted.vue';
import {nameEnv, cuponesUrl, getHeader} from '../../config/config';

export default {
  components: {
    NoServiceGranted,
  },
  data() {
    return {
      authUser: JSON.parse(window.localStorage.getItem('auth_ehealth')),
      dialog: false,
      show1: false,
      cupones: [],
      pacientes: [],
      fecha_actual: new Date(),
      headers: [
        {text: this.$t('cupones.codigo'), value: 'codigo'},
        {text: this.$t('common.descripcion'), value: 'descripcion'},
        {text: this.$t('cupones.descuento'), value: 'descuento'},
        {
          text: this.$t('common.actions'),
          value: '',
          sortable: false,
        },
      ],
      items: this.cupones,
      editedIndex: -1,
      editedItem: {
        id: 0,
        codigo: '',
        descuento: '',
        caducidad: 1,
        n_usos: '',
        paciente: 0,
        fecha: null,
        fechaMenu: false,
      },
      defaultItem: {},
      snackbar: false,
      dialog_delete: {
        name: '',
        dialog: false,
      },
      timeout: 5000,
      mode: '',
      alerta: {
        texto: '',
        icono: '',
        color: 'info',
      },
      dataPost: {
        user_id: JSON.parse(window.localStorage.getItem('auth_ehealth'))['id'],
        accion: '',
        indice: 0,
        cupon: {},
      },
      rules: {},
      rulesCode: [],
      tiposcaducidad: [
        {id: 1, name: ''},
        {id: 2, name: ''},
        {id: 0, name: ''},
      ],
      custom_template:
        window.localStorage.getItem('auth_ehealth') &&
        typeof JSON.parse(window.localStorage.getItem('auth_ehealth'))['colorHexadecimal'] !== 'undefined' &&
        JSON.parse(window.localStorage.getItem('auth_ehealth'))['colorHexadecimal'] !== ''
          ? true
          : false,
      es_medyola: nameEnv === 'medyola',
      showDontHaveService: false,
    };
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? this.$t('cupones.crear_nuevo') : this.$t('cupones.editar');
    },
  },
  created() {
    if (haveEprescriptionActive()) {
      this.showDontHaveService = true;
    } else {
      this.rules = {
        required: value => !!value || this.$t('common.obligatorio'),
        min: v => v.length >= 6 || this.$t('cupones.min_code'),
        equal6: v => v.length === 6 || this.$t('cupones.tam_code', {num: 6}),
      };
      this.rulesCode = this.es_medyola
        ? [this.rules.required, this.rules.equal6]
        : [this.rules.required, this.rules.min];
      this.tiposcaducidad[0].name = this.$t('cupones.porusos');
      this.tiposcaducidad[1].name = this.$t('cupones.porfecha');
      this.tiposcaducidad[2].name = this.$t('cupones.nocaduca');
      this.defaultItem = this.editedItem;

      const trans = {
        porusos: this.$t('cupones.caduca_por_usos'),
        sincaducidad: this.$t('cupones.sin_caducidad'),
        caducaporfecha: this.$t('cupones.caduca_por_fecha'),
        usarlocualquiera: this.$t('cupones.usarlo_cualquiera'),
        usarlopaciente: this.$t('cupones.usarlo_paciente'),
      };

      this.$http
        .post(cuponesUrl, {user_id: this.authUser.id, accion: 'index'}, {headers: getHeader()})
        .then(response => {
          this.cupones = response.data.cupones;
          this.cupones.forEach(function (cupon) {
            let descripcion =
              cupon.tipo === 1
                ? trans.porusos + ' [' + cupon.n_usos_restantes + '/' + cupon.n_usos + ']. '
                : trans.sincaducidad;
            if (cupon.tipo === 2) {
              const f = new Date(cupon.fecha_caducidad);
              cupon.fecha = cupon.fecha_caducidad;
              descripcion = trans.caducaporfecha + f.getDate() + '/' + (f.getMonth() + 1) + '/' + f.getFullYear();
            }
            descripcion += ' ';
            descripcion += cupon.paciente_id === 0 ? trans.usarlocualquiera : trans.usarlopaciente + cupon.fullName;
            cupon.descripcion = descripcion;
            cupon.descuento_pto = cupon.descuento + '%';
            cupon.paciente = cupon.paciente_id;
            cupon.caducidad = cupon.tipo;
          });

          this.pacientes = response.data.pacientes;
          this.pacientes.unshift({id: 0, name: this.$t('common.cualquiera')});
        });
    }
  },

  methods: {
    autoGenerarCodigo() {
      const chars = '0123456789ABCDEFGHJKLMNPQRSTXZ';
      const lon = 6;

      let code = '';
      for (let x = 0; x < lon; x++) {
        const rand = Math.floor(Math.random() * chars.length);
        code += chars.substr(rand, 1);
      }
      this.editedItem.codigo = code;
      this.editedItem.paciente = 0;
    },

    quitarDestinatario() {
      this.editedItem.destinatario = '';
    },

    editItem(item) {
      this.editedIndex = this.cupones.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.cupones.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog_delete.dialog = true;
      // confirm('Are you sure you want to delete this item?') && ;
    },

    borrarCupon() {
      this.dataPost.accion = 'eliminar';
      this.dataPost.cupon = this.editedItem.id;
      this.$http.post(cuponesUrl, this.dataPost, {headers: getHeader()}).then(response => {
        if (response.status === 200 && parseInt(response.data) === 1) {
          this.cupones.splice(this.editedIndex, 1);
          this.alerta.texto = this.$t('success.delete_coupon');
          this.alerta.color = 'success';
          this.alerta.icono = 'mdi-check';
        } else {
          this.alerta.texto = this.$t('errors.try_again');
          this.alerta.color = 'error';
          this.alerta.icono = 'mdi-alert-circle';
        }
        this.snackbar = true;
      });
    },

    cambiaDescuento() {
      if (this.editedItem.descuento < 0) {
        this.editedItem.descuento = 0;
      } else if (this.editedItem.descuento > 100) {
        this.editedItem.descuento = 100;
      }
    },

    cambiaNumUsos() {
      if (this.editedItem.n_usos < 1) {
        this.editedItem.n_usos = 1;
      }
    },

    validarCupon() {
      let res = true;
      this.alerta.texto = '';

      // validamos el formulario
      if (this.editedItem.codigo.trim() === '') {
        this.alerta.texto = this.$t('cupones.form_codigo');
      } else if (this.editedItem.descuento === '') {
        this.alerta.texto = this.$t('cupones.form_descuento');
      } else if (this.editedItem.caducidad === 1 && (this.editedItem.n_usos === '' || this.editedItem.n_usos < 1)) {
        this.alerta.texto = this.$t('cupones.form_nusos');
      } else if (this.editedItem.caducidad === 2 && this.editedItem.fecha === null) {
        this.alerta.texto = this.$t('cupones.form_fecha');
      }

      if (this.alerta.texto !== '') {
        res = false;
        this.alerta.color = 'warning';
        this.alerta.icono = 'mdi-alert-circle';
        this.snackbar = true;
      }

      return res;
    },

    close() {
      this.dialog = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },

    save() {
      if (this.validarCupon()) {
        this.dataPost.accion = this.editedIndex > -1 ? 'editar' : 'nuevo';
        this.dataPost.cupon = this.editedItem;
        this.dataPost.indice = this.editedIndex;
        this.$http.post(cuponesUrl, this.dataPost, {headers: getHeader()}).then(response => {
          if (response.status === 200 && response.data.resultado === '1') {
            this.alerta.texto =
              this.dataPost.accion === 'editar'
                ? this.$t('success.save_edit_coupon')
                : this.$t('success.save_new_coupon');
            this.alerta.color = 'success';
            this.alerta.icono = 'mdi-check';
            let descripcion =
              this.dataPost.cupon.caducidad === 1
                ? this.$t('cupones.caduca_por_usos') +
                ' [' +
                this.dataPost.cupon.n_usos +
                '/' +
                this.dataPost.cupon.n_usos +
                ']. '
                : this.$t('cupones.sin_caducidad');
            if (this.dataPost.cupon.caducidad === 2) {
              const f = new Date(this.dataPost.cupon.fecha);
              descripcion =
                this.$t('cupones.caduca_por_fecha') + f.getDate() + '/' + (f.getMonth() + 1) + '/' + f.getFullYear();
            }
            descripcion += ' ';
            descripcion +=
              this.dataPost.cupon.paciente === 0
                ? this.$t('cupones.usarlo_cualquiera')
                : this.$t('cupones.usarlo_paciente') + response.data.full_name;
            this.dataPost.cupon.descripcion = descripcion;
            this.dataPost.cupon.descuento_pto = this.dataPost.cupon.descuento + '%';

            if (this.dataPost.indice > -1) {
              Object.assign(this.cupones[this.dataPost.indice], this.dataPost.cupon);
            } else {
              this.dataPost.cupon.id = response.data.cupon_id;
              this.cupones.push(this.dataPost.cupon);
            }
          } else if (response.data.resultado === '2') {
            this.alerta.texto =
              this.dataPost.accion === 'editar'
                ? this.$t('cupones.error_editar_existe_codigo') + this.dataPost.cupon.codigo
                : this.$t('cupones.error_crear_existe_codigo') + this.dataPost.cupon.codigo;
            this.alerta.color = 'error';
            this.alerta.icono = 'mdi-alert-circle';
          } else {
            this.alerta.texto = this.$t('errors.try_again');
            this.alerta.color = 'error';
            this.alerta.icono = 'mdi-alert-circle';
          }
          this.snackbar = true;
        });
        this.close();
      }
    },
  },
};
</script>

<style>
.titulo_diag {
  font-family: Roboto;
  font-weight: 300 !important;
  margin-bottom: -10px;
}

.acciones {
  text-align: center;
}
</style>
